import type { FormItemProps } from "antd"
import { Form } from "antd"

export default function FormConfig() {
  return <></>
}

export type IFormConfig = FormItemProps[]

// can do some thing like set global confim for each for item. such as allowClear
export const createFormItems = (formItems: IFormConfig) => {
  return (
    <>
      {formItems.filter(Boolean).map((item, index) => {
        return (
          <Form.Item key={index} {...item}>
            {item.children}
          </Form.Item>
        )
      })}
    </>
  )
}
