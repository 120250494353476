export default {
  DASHBOARD: "仪表盘",
  STAFF_MANAGE: "员工管理",
  STAFF_LIST: "员工列表",
  ROLE_MANAGE: "角色管理",
  ROLE_LIST: "角色列表",
  LOGGER_MANAGE: "日志管理",
  LOGGER_LOGIN: "登录日志",
  LOGGER_OPERATION: "操作日志",
  SECURITY_MANAGE: "安全设置",
  IP_WHITELIST: "IP白名单",
  ACCOUNT_WHITELIST: "账号白名单",
  SYSTEM_SETTING: "系统设置",
  WHITE_LIST: "白名单",
  ACCOUNT_SETTING: "账户设置",
  GLOBAL_SETTING: "全局参数",
}
