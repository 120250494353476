import type { IStaff } from "@packages/types"
import type { DeepPartial, PayloadAction } from "@reduxjs/toolkit"
import { createSlice } from "@reduxjs/toolkit"

// Define a type for the slice state
export interface IUserState extends DeepPartial<IStaff> {
  _id: string
  name: string
  phone: string
  username: string
  password: string
  // status: "active" | "inactive"
  role: string
  // 权限信息
  roleInfo: {
    name: string
    routeAuth: string[]
  }
}

// Define the initial state using that type
const initialState: DeepPartial<IUserState> = {}

export const userSlice = createSlice({
  name: "user",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setUser(state, action: PayloadAction<DeepPartial<IUserState>>) {
      for (const key in state) {
        delete state[key]
      }
      const newState = { ...initialState, ...action.payload }
      for (const key in newState) {
        state[key] = newState[key]
      }
    },
  },
})

export const { setUser } = userSlice.actions

const UserReducer = userSlice.reducer
export default UserReducer
