import { message } from "antd"
import axios from "axios"

import config from "../../config/env"
import { refreshToken } from "../staff"

const instance = axios.create({
  baseURL: config.instance.baseURL,
  timeout: 10000,
})

instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("accessToken")
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`
    }

    return config
  },
  (error) => {
    return Promise.reject(error)
  },
)

let isRefreshing = false

const requests = []

instance.interceptors.response.use(
  (response) => {
    const { method } = response.config
    if (["post", "put", "delete", "patch"].includes(method)) {
      message.destroy()
      message.success("Success!")
    }
    return response.data
  },
  async (error) => {
    console.log("error", error)
    const status = error?.response?.status
    const originalRequestConfig = error.config
    console.log("status", originalRequestConfig.url, status)
    if (status === 401) {
      if (originalRequestConfig.url === "/staffs/refreshToken") {
        console.log("refreshToken error")
        localStorage.removeItem("accessToken")
        localStorage.removeItem("refreshToken")
        window.location.reload()
        return Promise.reject(error)
      }
      /**
       * refresh token
       * re-send the initial request
       */
      // cache the request
      if (isRefreshing) {
        return new Promise((resolve) => {
          requests.push(() => {
            resolve(instance(originalRequestConfig))
          })
        })
      } else {
        isRefreshing = true
        return refreshToken()
          .then(async (res: any) => {
            localStorage.setItem("accessToken", res.accessToken)
            requests.forEach((cb) => cb?.())
            return instance(originalRequestConfig) // re-send initial request
          })
          .catch((error) => {
            localStorage.clear()
            window.location.reload()
            return Promise.reject(error)
          })
          .finally(() => {
            isRefreshing = false
          })
      }
    }

    const info: { code?: number; message?: string } = {}
    if (!error?.response) {
      info.code = 500
      info.message = "Network Error"
    } else {
      info.code = error?.response.data?.code || 500
      info.message = error?.response.data?.message || "Internal Server Error"
    }
    message.destroy()
    message.error(info.message)
    return Promise.reject(info)
  },
)
export default instance
