import type { PayloadAction } from "@reduxjs/toolkit"
import { createSlice } from "@reduxjs/toolkit"

import type { IRoute } from "../../router"

// Define a type for the slice state
export interface ITagState {
  tags: IRoute[]
}

// Define the initial state using that type
const initialState: ITagState = {
  tags: [],
}

export const themeSlice = createSlice({
  name: "tag",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setTags(state, action: PayloadAction<IRoute[]>) {
      state.tags = action.payload
    },
  },
})

export const { setTags } = themeSlice.actions

const TagReducer = themeSlice.reducer
export default TagReducer
