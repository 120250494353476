import { useFullscreen, useSize } from "ahooks"
import { FloatButton } from "antd"
import { useEffect, useRef } from "react"

import useNProgressColor from "../hooks/useNProgressColor"
import { useTheme } from "../store"
import MixLayout from "./mixLayout"
import MobileLayout from "./mobileLayout"
import SideLayout from "./sideLayout"
import TopLayout from "./topLayout"

export default function BasicLayout() {
  const { theme, setTheme } = useTheme()

  const fullscreenRef = useRef(null)
  const size = useSize(fullscreenRef)

  const [isFullscreen, { enterFullscreen, exitFullscreen }] = useFullscreen(fullscreenRef, {
    onExit: () => setTheme({ ...theme, isFullscreen: false }),
  })

  if (size?.width > 640 && theme.isMobile) {
    setTheme({ ...theme, isMobile: false })
  } else if (size?.width <= 640 && !theme.isMobile) {
    setTheme({ ...theme, isMobile: true })
  }
  // set nprogress bar color
  useNProgressColor(theme.primaryColor)

  useEffect(() => {
    if (theme.isFullscreen) {
      enterFullscreen()
    } else {
      exitFullscreen()
    }
  }, [theme.isFullscreen])

  return (
    <>
      {/* here name id fullscreen to make sure the ant design all component are topper */}
      <div ref={fullscreenRef} id={(isFullscreen && "fullscreen") || "non-fullscreen"}>
        {!theme.isMobile ? (
          <div className="hidden sm:block">
            {theme.layout == "mix" ? <MixLayout /> : theme.layout == "top" ? <TopLayout /> : <SideLayout />}
          </div>
        ) : (
          <div className="block sm:hidden">
            <MobileLayout />
          </div>
        )}

        <FloatButton.BackTop />
      </div>
    </>
  )
}
