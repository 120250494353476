import { Eye, EyeSlash, Lock, Translate, User } from "@phosphor-icons/react"
import { useCountDown } from "ahooks"
import type { ModalProps } from "antd"
import { Button, Checkbox, Image, Input, message, Modal, notification } from "antd"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

import * as StaffApi from "../../api/staff"
import ChangeLanguage from "../../components/common/ChangeLanguage"
import { createFormItems } from "../../components/form/formConfig"
import { useGlobal, useTheme } from "../../store"

export function timeFix() {
  const hour = new Date().getHours()
  return hour < 12 ? "Good Morning" : hour < 18 ? "Good Afternoon" : "Good Evening"
}

const generateRandomHex = () =>
  `#${Math.floor(Math.random() * 0xffffff)
    .toString(16)
    .padEnd(6, "0")}`
const generateRandomDegreeArr = () => [0, 0].map(() => Math.floor(Math.random() * 101)).sort((a, b) => a - b)
const background = `linear-gradient(
${Math.floor(Math.random() * 181)}deg,
${generateRandomDegreeArr().map((i) => `${generateRandomHex()} ${i}%`)}
)`

function Need2FAModal({
  qrcode,
  username,
  onRefresh,
  ...props
}: ModalProps & { qrcode: string; username: string; onRefresh?: () => void }) {
  const [otpCode, setOtpCode] = useState("")
  const [leftTime, setLeftTime] = useState(0)

  const handleConfirmScan = async () => {
    try {
      await StaffApi.confirmScanQrCode({ username, otpCode })
      setOtpCode("")
      props.onCancel?.(null)
    } catch (error) {
      console.log(error)
    }
  }

  const [countdown, formattedRes] = useCountDown({
    leftTime,
    onEnd: () => setLeftTime(0),
  })
  useEffect(() => {
    setLeftTime(60 * 1000)
  }, [qrcode])

  const { minutes, seconds } = formattedRes

  return (
    <Modal width={"max-content"} {...props} centered closable={false} maskClosable={false} footer={false}>
      <div className=" p-2">
        <h2>Use Google Authenticator Scan QR Code</h2>

        <div className=" relative mx-auto flex w-max items-center justify-center">
          <Image src={qrcode} width={212} height={212} />

          {countdown <= 0 && (
            <div
              className="absolute inset-0 flex items-center justify-center bg-[#fffffff5]"
              onClick={(e) => e.stopPropagation()}
            >
              <div>
                <div className="mb-1">QrCode Expired</div>
                <div className=" flex items-center justify-center">
                  <Button type="primary" size="small" onClick={() => onRefresh?.()}>
                    Refresh
                  </Button>
                </div>
              </div>
            </div>
          )}
        </div>

        {countdown > 0 && (
          <div className=" mb-4  text-center">
            Expired In {minutes}:{seconds}
          </div>
        )}

        <div className="flex items-center justify-center">
          <Input
            autoFocus
            className=" mb-4 max-w-full "
            size="large"
            placeholder={"Google Authenticator Code"}
            value={otpCode}
            onChange={(e) => setOtpCode(e.target.value)}
            onPressEnter={() => handleConfirmScan()}
          />
        </div>
        <Button type="primary" block disabled={otpCode.length < 6} onClick={() => handleConfirmScan()}>
          I have scan QR Code
        </Button>
      </div>
    </Modal>
  )
}

export default function Login() {
  const navigate = useNavigate()
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [otpCode, setOtpCode] = useState("")
  const [need2FA, setNeed2FA] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [rememberMe, setRememberMe] = useState(false)
  const [loading] = useState(false) // login button loading
  const [qrcode, setQrcode] = useState("")
  const [modal, setModal] = useState<ModalProps>()
  const { global } = useGlobal()

  const { t } = useTranslation()
  const { theme } = useTheme()
  const { primaryColor } = theme

  const handleLogin = async (refresh?: boolean) => {
    let res: any //{ accessToken: string; refreshToken: string }
    try {
      if (!username) {
        return message.error("Please Input Username")
      }
      if (!password) {
        return message.error("Please Input Password")
      }

      // normal login procedure
      if (!refresh) {
        if (need2FA && !otpCode) {
          return message.error("Please Input OTP Code")
        }
      }

      res = await StaffApi.login({ type: "username", username, password, otpCode })

      if (res.need2FA) {
        message.destroy()
        message.info("Need 2FA！ Please Input OTP Code")
        setNeed2FA(true)
        setQrcode(res.qrCode)
        res.showScanQrCode && setModal({ ...modal, open: true })
        return
      }

      rememberMe ? localStorage.setItem("LoginUserName", username) : localStorage.removeItem("LoginUserName")

      localStorage.setItem("accessToken", res.accessToken)
      localStorage.setItem("refreshToken", res.refreshToken)
      navigate("/order/orderInfo", { replace: true })

      setTimeout(() => {
        notification.success({
          message: "👏👏👏Welcome👏👏👏",
          description: `${timeFix()},  Welcome Back `,
        })
      }, 1000)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      <div className="relative flex h-full w-full items-center justify-center" style={{ background }}>
        <div className="absolute right-0 top-0 flex w-full items-center justify-end ">
          <ChangeLanguage>
            <div className="mr-4 flex cursor-pointer items-center p-3">
              <Translate size={32} weight="fill" />
            </div>
          </ChangeLanguage>
        </div>

        {/* login content */}
        <div className="m-3 w-full rounded-2xl bg-white p-10 px-4 sm:px-10 md:m-0 md:w-max">
          {createFormItems(
            [
              { children: <h1 className=" text-center text-3xl">{global.siteTitle || "Gganbu Admin"}</h1> },
              {
                required: true,
                children: (
                  <Input
                    className=" max-w-full md:w-[350px]"
                    allowClear
                    size="large"
                    placeholder={t("USERNAME")}
                    prefix={<User size={20} />}
                    value={username}
                    onChange={(e) => {
                      setUsername(e.target.value)
                      setNeed2FA(false) // once change username, need2FA should be false
                    }}
                    onPressEnter={() => handleLogin()}
                  />
                ),
              },
              {
                required: true,
                children: (
                  <Input
                    className=" max-w-full md:w-[350px]"
                    size="large"
                    type={!showPassword && "password"}
                    placeholder={t("PASSWORD")}
                    prefix={<Lock size={20} />}
                    suffix={
                      (showPassword && (
                        <Eye className=" cursor-pointer" onClick={() => setShowPassword(!showPassword)} size={20} />
                      )) || (
                        <EyeSlash
                          className=" cursor-pointer"
                          onClick={() => setShowPassword(!showPassword)}
                          size={20}
                        />
                      )
                    }
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    onPressEnter={() => handleLogin()}
                  />
                ),
              },
              need2FA && {
                required: true,
                children: (
                  <Input
                    autoFocus
                    className=" max-w-full md:w-[350px]"
                    size="large"
                    placeholder={"Google Authenticator Code"}
                    value={otpCode}
                    onChange={(e) => setOtpCode(e.target.value)}
                    onPressEnter={() => handleLogin()}
                  />
                ),
              },
              {
                children: (
                  <>
                    <div className="flex items-center justify-between" style={{ color: primaryColor }}>
                      <Checkbox
                        checked={rememberMe}
                        onChange={(e) => setRememberMe(e.target.checked)}
                        style={{ color: primaryColor }}
                        disabled
                      >
                        {t("login.REMEMBER_ME")}
                      </Checkbox>
                      <div className=" cursor-not-allowed">{t("login.FORGOT_PASSWORD")}</div>
                    </div>
                  </>
                ),
              },
              {
                children: (
                  <Button block loading={loading} size="large" type="primary" onClick={() => handleLogin()}>
                    {t("login.SIGN_IN")}
                  </Button>
                ),
              },
            ].filter(Boolean),
          )}
        </div>
      </div>

      <Need2FAModal
        qrcode={qrcode}
        username={username}
        onRefresh={() => handleLogin(true)}
        onCancel={() => setModal({ ...modal, open: false })}
        {...modal}
      />
    </>
  )
}
