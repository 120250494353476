import { combineReducers } from "@reduxjs/toolkit"

import GlobalReducer from "./global"
import TagReducer from "./tag"
import ThemeReducer from "./theme"
import UserReducer from "./user"

export const reducers = combineReducers({
  theme: ThemeReducer,
  tag: TagReducer,
  user: UserReducer,
  global: GlobalReducer,
})
