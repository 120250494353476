import { Eye, EyeSlash } from "@phosphor-icons/react"
import type { ModalProps } from "antd"
import { Input, message, Modal } from "antd"
import { useState } from "react"
import { useTranslation } from "react-i18next"

import * as StaffApi from "../../api/staff"

export default function ResetPasswordModal({ userId, ...props }: ModalProps & { userId: string }) {
  const [value, setValue] = useState("") // password
  const [showPassword, setShowPassword] = useState(false)
  const { t } = useTranslation()

  return (
    <Modal
      destroyOnClose
      title={t("RESET_PASSWORD")}
      {...props}
      onOk={async (e) => {
        if (!value) {
          return message.error("Please input new password")
        }
        try {
          await StaffApi.resetPassword(userId, value)
          props.onCancel?.(e)
        } catch (error) {
          console.log(error)
        }
      }}
    >
      <div className=" px-0 py-4">
        <Input
          autoFocus
          type={!showPassword && "password"}
          suffix={
            (showPassword && (
              <Eye className=" cursor-pointer" onClick={() => setShowPassword(!showPassword)} size={20} />
            )) || <EyeSlash className=" cursor-pointer" onClick={() => setShowPassword(!showPassword)} size={20} />
          }
          onChange={(e) => setValue(e.target.value)}
          placeholder="please input new password"
        />
      </div>
    </Modal>
  )
}
