export default {
  DASHBOARD: "Dashboard",
  STAFF_MANAGE: "Staff",
  STAFF_LIST: "Staff List",
  ROLE_MANAGE: "Role",
  ROLE_LIST: "Role List",
  LOGGER_MANAGE: "Logger",
  LOGGER_LOGIN: "Login Log",
  LOGGER_OPERATION: "Operation Log",
  SECURITY_MANAGE: "Security",
  IP_WHITELIST: "IP Whitelist",
  ACCOUNT_WHITELIST: "Account Whitelsit",
  SYSTEM_SETTING: "System",
  WHITE_LIST: "Whitelist",
  ACCOUNT_SETTING: "Account Setting",
  GLOBAl_SETTING: "Global Setting",
}
